import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component:  ()=>import('@/views/Login'),
  },
  {
    path: '/Main',
    name: 'Main',
    component:  ()=>import('@/views/Main'),
    children:[
      {
        path: '/Home',
        name: 'Home',
        component:  ()=>import('@/views/Home'),
        meta:{
          title: '首页',
          isTab: true
        }
      },
      {
        path: '/manager',
        name: 'manager',
        component:  ()=>import('@/views/setting/manager'),
        meta:{
          title: '管理员设置',
          isTab: true,
          close: true
        }
      },
      {
        path: '/noDelivery',
        name: 'noDelivery',
        component:  ()=>import('@/views/setting/noDelivery'),
        meta:{
          title: '不配送日期',
          isTab: true,
          close: true
        }
      },
      {
        path: '/swiperPic',
        name: 'swiperPic',
        component:  ()=>import('@/views/setting/swiperPic'),
        meta:{
          title: '轮播背景',
          isTab: true,
          close: true
        }
      },
      {
        path: '/goodsType',
        name: 'goodsType',
        component:  ()=>import('@/views/setting/goodsType'),
        meta:{
          title: '产品类型',
          isTab: true,
          close: true
        }
      },
      {
        path: '/orderQuery',
        name: 'orderQuery',
        component:  ()=>import('@/views/order/orderQuery'),
        meta:{
          title: '订单查询',
          isTab: true,
          close: true
        }
      },
      {
        path: '/orderCus',
        name: 'orderCus',
        component:  ()=>import('@/views/order/orderCus'),
        meta:{
          title: '客户订单',
          isTab: true,
          close: true
        }
      },
      {
        path: '/orderDelivery',
        name: 'orderDelivery',
        component:  ()=>import('@/views/order/orderDelivery'),
        meta:{
          title: '配送列表',
          isTab: true,
          close: true
        }
      },
      {
        path: '/cusManage',
        name: 'cusManage',
        component:  ()=>import('@/views/customer/cusManage'),
        meta:{
          title: '客户管理',
          isTab: true,
          close: true
        }
      },
      {
        path: '/cusAddress',
        name: 'cusAddress',
        component:  ()=>import('@/views/customer/cusAddress'),
        meta:{
          title: '客户地址',
          isTab: true,
          close: true
        }
      },
      {
        path: '/deliveryToday',
        name: 'deliveryToday',
        component:  ()=>import('@/views/report/deliveryToday'),
        meta:{
          title: '当日报表',
          isTab: true,
          close: true
        }
      },
      {
        path: '/deliveryReport',
        name: 'deliveryReport',
        component:  ()=>import('@/views/report/deliveryReport'),
        meta:{
          title: '报表',
          isTab: true,
          close: true
        }
      },
      {
        path: '/adminLog',
        name: 'adminLog',
        component:  ()=>import('@/views/setting/adminLog'),
        meta:{
          title: '操作日志',
          isTab: true,
          close: true
        }
      },
      {
        path: 'batchNoSend',
        name: 'batchNoSend',
        component:  ()=>import('@/views/setting/batchNoSend'),
        meta:{
          title: '批量设置',
          isTab: true,
          close: true
        }
      },
      {
        path: 'firstPic',
        name: 'firstPic',
        component:  ()=>import('@/views/setting/firstPic'),
        meta:{
          title: '首页首图',
          isTab: true,
          close: true
        }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
