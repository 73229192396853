import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ELementPlusIconsVue from '@element-plus/icons-vue'                  //引入Element-icons需npm 安装
import zhCn from 'element-plus/es/locale/lang/zh-cn'        //引入中文包

const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(zhCn)

//===全局注册ElementPlusIcons
for (const [name, component] of Object.entries(ELementPlusIconsVue)){
    app.component(name, component);
}
//END 全局注册ElementPlusIcons

app.mount('#app')

//全局变量
// app.config.globalProperties.$baseUrl = "http://127.0.0.1:8888/"
// app.config.globalProperties.$baseUrl = "http://47.109.28.219:8888/"
// app.config.globalProperties.$baseUrl = "https://mwhujun.mynatapp.cc/"
app.config.globalProperties.$baseUrl = "https://service.drcalorie.cn/"
app.config.globalProperties.$minIOUrl = "https://upload.drcalorie.cn/home/"
app.config.globalProperties.$basePhpUrl = "https://www.luckydog.design/PHP/calorie/api/"
// app.config.globalProperties.$basePhpUrl = "https://www.luckydog.design/PHP/silkRoad/"
//END 全局变量

//封装全局Ajax公共函数
app.config.globalProperties.$requestData = function (url, method, data, async, fun, that){
    $.ajax({
        url: this.$baseUrl+url,
        type: method,
        dataType: 'json',
        contentType: 'application/json',
        xhrFields:{
            withCredentials: true
        },
        headers:{
            "token": localStorage.getItem("calorieToken")
        },
        async: async,
        data: JSON.stringify(data),
        success: function (res){
            that.loading = false
            if (that.dialogLoading){
                that.dialogLoading = false
            }
            fun(res)
        },
        fail: function (res){
            console.log('Request Fail')
            console.log(res)
            that.loading = false
        },
        error: function (res){
            console.log('Request Error')
            console.log(res)
            if (res.responseJSON.error){
                that.$message.error(res.responseJSON.error)
            }
            that.loading = false
        },
    })
}
//END 封装全局Ajax公共函数


//===BEGIN 全局ajax请求方法 BY PHP======
app.config.globalProperties.$requestPhpData=function (phpFile,phpMethod,paraData,func,that){
    $.ajax({
        url: that.$basePhpUrl+phpFile,
        type: phpMethod,
        dataType: 'json',
        xhrFields: {
            withCredentials: true
        },
        headers: {
            "token": localStorage.getItem("calorieToken")
        },
        async: true,
        data: paraData,
        success: function(res) {
            that.loading = false
            func(res)
        },
        fail: function(res){
            that.loading =  false
            that.$message.error('请求失败')
        },
        error: function(rese) {
            that.loading = false
            that.$message.error('操作错误')
            console.log(rese)
        }
    })
}
//===END 全局ajax请求方法 BY PHP===

app.config.globalProperties.$toDate = function (number){
    let n = number * 1000;
    let date = new Date(n);
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return (Y + M + D)
}


