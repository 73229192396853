<template>
      <el-config-provider :locale="locale">
<!--            <keep-alive exclude="searchResult">-->
            <router-view></router-view>
<!--            </keep-alive>-->
      </el-config-provider>
</template>

<script>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";

// 解决ERROR ResizeObserver loop completed with undelivered notifications.的问题
const debounce = (fn, delay) => {
      let timer = null;
      return function () {
            let context = this;
            let args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                  fn.apply(context, args);
            }, delay);
      }
}


const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
            callback = debounce(callback, 16);
            super(callback);
      }
}
// END 解决ERROR ResizeObserver loop completed with undelivered notifications.的问题


export default {
      components: {
            [ElConfigProvider.name]: ElConfigProvider
      },
      setup(){
            let locale = zhCn
            return{
                  locale
            }
      }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
      margin: 0;
}

/*BEGIN 页面布局相关*/
.divPlateContainer{
      width: 99.8%; border-radius: 10px;
      border: 1px solid #efefef;
      padding: 10px 0px 20px 0px;
      align-items: center; font-size: 14px;
      display: flex; flex-direction: row; flex-wrap: wrap;
      margin-bottom: 20px;
}

.divPlateSearch{
      width: 99.8%;
      border-bottom: 1px solid #efefef;
      padding: 0px 0px 10px 0px;
      align-items: center; font-size: 14px;
      display: flex; flex-direction: row; flex-wrap: wrap;
      margin-bottom: 20px;
}

.divPlateInnerContainer{
      width: 100%;
      margin-left: 20px; margin-right: 20px;
      display: flex; flex-direction: row; flex-wrap: wrap;
}
.divPlateTitle{
      font-weight: bolder; background-color: #efefef; line-height: 40px; width: 100%;
}
.divPlateTitle span{
      margin-left: 20px;
}
.divCell{
      width: 50%; height: 50px; line-height: 50px; align-items: center; margin-top: 5px;
}
.inputCell{
      width: 40% !important;
}

/*END 页面布局相关*/

/*BEGIN 应用于内页头的搜索框*/
.divInnerTop{
      width: 100%; border-radius: 10px; background-color: #f8f9fb;
      padding: 20px 0px 20px 0px;
      align-items: center; font-size: 14px;
      display: flex; flex-direction: row; flex-wrap: wrap;
}
.divInnerTop div{
      align-items: center; margin-top: 2px; margin-bottom: 2px;
}
/*END 应用于内页头的搜索框*/

/*BEGIN elementPlus修改*/
.el-table{        /*修改el-table表头颜色*/
      --el-table-header-bg-color: #f8f9fb !important;
}
.el-dialog{
      margin-top: 12% !important;
}
            /*BEGIN 修改el-table鼠标经过颜色*/
.el-table tbody tr:hover>td {
      background: #f1f4ff !important;
}
.el-table__body .el-table__row.hover-row td{
      background-color: #f1f4ff !important;
}
            /*END 修改el-table鼠标经过颜色*/

/* END elementPlus修改*/
</style>
